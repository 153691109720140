<script>
	import ClientDataService from "@/services/ClientDataService";
	import EmployeeService from "@/services/EmployeeService";

	import ProgressBar from "@/components/UI/ProgressBar";

	export default {
		components: {
			ProgressBar,
		},
		props: [
			"client",
			"engType",
			"deliveryDate",
			"progress",
			"akfers",
			"tasks",
			"checklists",
			"phase",
			"engID",
			"engStartDate",
		],
		data() {
			return {
				today: new Date().toLocaleDateString("en-US"),
				employeeList: {}
			};
		},
		mounted() {
			EmployeeService.getEmployeeList().then(employeeList => this.employeeList = employeeList)
		},
		methods: {
			updateChecklist(checklistID, ID) {
				console.log("engID ", this.engID);
				ClientDataService.updateChecklist(this.client, this.engID, this.checklists, checklistID, ID)
					.then(() => {
						console.log("Updated checklist item");
					})
					.catch((e) => {
						console.log(e);
					});
			},
		},
		computed: {
			getChecklist() {
				var checklist = this.checklists[this.phase].checklist;
				return checklist;
			},
		},
	};
</script>

<template>
	<div class="flex flex-col justify-between mb-6 overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-600">
		<div class="pb-3 ">
			<div class="flex items-center">
				<div class="flex-1 w-0 ml-2">
					<dl>
						<dt class="pt-1 pr-3 text-sm font-semibold text-right text-blue-600 truncate dark:text-blue-300">
							{{ engType }}
						</dt>

						<dd class="px-5">
							<div class="text-2xl font-medium text-gray-900 dark:text-blue-400">{{ client }}</div>
							<div class="-mt-1">
								<span
									v-for="(akfer, index) in akfers"
									:key="index"
									class="mr-1 text-xs text-gray-400 dark:text-gray-300"
									>{{ employeeList[akfer]?.nickName }}<span v-if="index < akfers.length - 1">,</span></span
								>
							</div>
						</dd>
						<dd class="px-5">
							<fieldset class="mt-3">
								<div v-for="(item, index) in getChecklist" :key="index" class="relative flex items-start">
									<div class="flex items-center h-5">
										<input
											id="comments"
											aria-describedby="comments-description"
											name="comments"
											type="checkbox"
											v-model="item.done"
											@change="updateChecklist(checklists[phase], index)"
											class="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
										/>
									</div>
									<div class="ml-3 text-sm">
										<span id="comments-description" class="text-gray-500 dark:text-gray-200">{{ item.item }} </span>
									</div>
								</div>
							</fieldset>
						</dd>
					</dl>
				</div>
			</div>
		</div>
		<div class="pb-3 bg-white dark:bg-gray-600 dark:text-gray-300">
			<div v-if="engStartDate || deliveryDate">
				<div v-if="phase == '0' && engStartDate" class="px-3 mt-2 mb-3 text-xs text-right ">
					eng start date:
					<span class="px-2 ml-1 text-xs text-orange-100 bg-orange-500 rounded-full py-half">
						{{ engStartDate.toDate().toDateString() }}
					</span>
				</div>
				<div v-if="phase == '1' && deliveryDate" class="px-3 mt-2 mb-3 text-xs text-right ">
					deliver by:
					<span class="px-2 ml-1 text-xs text-blue-100 bg-blue-600 rounded-full py-half">
						{{ deliveryDate.toDate().toDateString() }}
					</span>
				</div>
				<div v-if="phase == '2' && deliveryDate" class="px-3 mt-2 mb-3 text-xs text-right ">
					deliver by:
					<span class="px-2 ml-1 text-xs text-teal-100 bg-teal-400 rounded-full py-half">
						{{ deliveryDate.toDate().toDateString() }}
					</span>
				</div>
			</div>
			<ProgressBar class="mt-2 mb-3" :progress="progress" />
			<div class="px-5 text-sm">
				<router-link
					:to="'/client/' + client + '/' + engID"
					class="flex items-center justify-center font-medium text-blue-800 hover:text-blue-600 dark:text-blue-300 dark:hover:text-blue-400"
				>
					<svg class="w-5 h-5 mr-2 -ml-1 fill-current" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
						<g>
							<path
								d="m76 7.7695h-52c-6.0742 0-11 4.9258-11 11v62.461c0 6.0742 4.9258 11 11 11h52c6.0742 0 11-4.9258 11-11v-62.461c0-6.0742-4.9258-11-11-11zm5 73.461c0 1.3242-0.52734 2.5977-1.4648 3.5352s-2.2109 1.4648-3.5352 1.4648h-52c-2.7617 0-5-2.2383-5-5v-62.461c0-2.7617 2.2383-5 5-5h52c1.3242 0 2.5977 0.52734 3.5352 1.4648s1.4648 2.2109 1.4648 3.5352z"
							/>
							<path
								d="m32.352 47.5h-4.9531c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h4.9531c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3z"
							/>
							<path
								d="m72.602 47.5h-29.312c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h29.312c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3z"
							/>
							<path
								d="m32.352 29.5h-4.9531c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h4.9531c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3z"
							/>
							<path
								d="m72.602 29.5h-29.312c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h29.312c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3z"
							/>
							<path
								d="m32.352 65.5h-4.9531c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h4.9531c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3z"
							/>
							<path
								d="m72.602 65.5h-29.312c-1.6562 0-3 1.3438-3 3s1.3438 3 3 3h29.312c1.6562 0 3-1.3438 3-3s-1.3438-3-3-3z"
							/>
						</g>
					</svg>

					View client details
				</router-link>
			</div>
		</div>
	</div>
</template>

<style scoped>
	.py-half {
		padding-top: 0.125rem;
		padding-bottom: 0.125rem;
	}
</style>
