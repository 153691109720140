<script>
  export default {
    props: ["progress"],
  };
</script>

<template>
  <div class="relative px-0 ">
    <div class="flex h-1 mb-0 overflow-hidden text-xs bg-blue-200 ">
      <div
        :style="{ width: progress + '%' }"
        class="flex flex-col justify-center text-center text-white bg-blue-500 shadow-none whitespace-nowrap"
      ></div>
    </div>
  </div>
</template>

<style scoped></style>
