<script>
	import { repgen } from "@/firebase";

	import BaseButton from "@/components/UI/BaseButton.vue";
	import ModalNewClient from "@/components/UI/ModalNewClient.vue";

	import ClientCard from "@/components/UI/ClientCard";
	import { collection, getDocs, query, where } from "@firebase/firestore";
import { getCurrentUser } from "../firebase";

	// import Clients from "@/data/clients";
	export default {
		components: {
			ClientCard,
			BaseButton,
			ModalNewClient,
		},
		data() {
			return {
				expires: localStorage.getItem("texp"),
				clients: [],
				upcomingClients: [],
				wipClients: [],
				finishClients: [],
				today: new Date(),
				newClientModal: false,
			};
		},

		methods: {
			closeModal() {
				this.newClientModal = false;
			},
			async getClients() {
				const userID = (await getCurrentUser()).uid
				const querySnapshot = await getDocs(collection(repgen, "client-engagements"));
				querySnapshot.forEach(async (doc) => {
					const q = query(collection(doc.ref, "engagements"), where("active", "==", true));
					const engagementsSnapshot = await getDocs(q);
					engagementsSnapshot.forEach((engagementSnapshot) => {
						let engagement = engagementSnapshot.data()
						if(engagement.akfers.some(akfer => akfer == userID)) {
							this.clients.push(engagement);
						}
					});
				});
			},
		},

		mounted() {
			this.getClients();
		},
		computed: {
			getMyUpcoming() {
				const myUpcoming = this.clients.filter(function(el) {
					return el.phase == "0";
				});
				return myUpcoming;
			},
			getMyInProgress() {
				const myInProgress = this.clients.filter(function(el) {
					return el.phase == "1";
				});
				return myInProgress;
			},
			getMyFinishOut() {
				const myFinishOut = this.clients.filter(function(el) {
					return el.phase == "2";
				});
				return myFinishOut;
			},
		},
	};
</script>

<template>
	<ModalNewClient v-if="newClientModal" @close-modal="closeModal" />
	<div class="dashboard">
		<div class="mb-24">
			<div class="mt-12">
				<BaseButton @click="newClientModal = !newClientModal" mode="success" class="mb-12">
					Add New Engagement
				</BaseButton>
			</div>

			<div class="max-w-6xl">
				<h2 class="mb-6 text-2xl font-semibold leading-6 text-blue-900 dark:text-blue-600">
					Your Active Engagements
				</h2>
				<div class="flex flex-wrap space-x-0 md:space-x-5 space-y-5 md:space-y-0">
					<div class="w-full md:flex-1 px-6 pt-5 pb-10 bg-gray-200 dark:bg-gray-800 dark:bg-opacity-60">
						<h3 class="mb-4 text-xl font-medium text-gray-600 dark:text-gray-400">Upcoming</h3>
						<ClientCard
							v-for="client in getMyUpcoming"
							:key="client.engID"
							:client="client.client"
							:engType="client.engType"
							:deliveryDate="client.engStartDate"
							:engStartDate="client.engStartDate"
							:progress="client.progress"
							:akfers="client.akfers"
							:checklists="client.checklists"
							:phase="client.phase"
							:engID="client.engID"
						/>
					</div>
					<div class="w-full md:flex-1 px-6 pt-5 pb-10 bg-gray-200 dark:bg-gray-800 dark:bg-opacity-60">
						<h3 class="mb-4 text-xl font-medium text-blue-500">In Progress</h3>
						<ClientCard
							v-for="client in getMyInProgress"
							:key="client.engID"
							:client="client.client"
							:engType="client.engType"
							:deliveryDate="client.deliveryDate"
							:engStartDate="client.engStartDate"
							:progress="client.progress"
							:akfers="client.akfers"
							:checklists="client.checklists"
							:phase="client.phase"
							:engID="client.engID"
						/>
					</div>
					<div class="w-full md:flex-1 px-6 pt-5 pb-10 bg-gray-200 dark:bg-gray-800 dark:bg-opacity-60">
						<h3 class="mb-4 text-xl font-medium text-teal-500">Finish Out</h3>
						<ClientCard
							v-for="client in getMyFinishOut"
							:key="client.engID"
							:client="client.client"
							:engType="client.engType"
							:deliveryDate="client.engStartDate"
							:engStartDate="client.engStartDate"
							:progress="client.progress"
							:akfers="client.akfers"
							:checklists="client.checklists"
							:phase="client.phase"
							:engID="client.engID"
						/>
					</div>
				</div>
				<div class="grid grid-cols-1 gap-5 mt-2 sm:grid-cols-2 lg:grid-cols-4"></div>
			</div>
		</div>
	</div>
</template>
